import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  // .scrollTo({ 
  //   top: 0,  
  //   behavior: 'smooth'
  // }); 
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  return (
    <ul className="pagination">
      <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
        <a className="page-link page-prev" href="#" aria-label="Previous" onClick={(e) => { e.preventDefault(); handlePageChange(currentPage - 1); }}>
         
        </a>
      </li>
      {/* {[...Array(totalPages)].map((_, index) => (
        <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
          <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); handlePageChange(index + 1); }}>
            {index + 1}
          </a>
        </li>
      ))} */}
      <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
        <a className="page-link page-next" href="#" aria-label="Next" onClick={(e) => { e.preventDefault(); handlePageChange(currentPage + 1); }}>
          
        </a>
      </li>
    </ul>
  );
};

export default Pagination;
