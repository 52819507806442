import React, { useState, useEffect } from 'react';
import ReviewTile from '../components/reviewTile';
import ReviewDetails from '../components/reviewDetailTiles';
import axios from 'axios';
import Pagination from '../components/pagination';

const MainContent = ({ status }) => {
    const [reviews, setReviews] = useState([]);
    const [selectedReview, setSelectedReview] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [submittedSearchTerm, setSubmittedSearchTerm] = useState('');
    const [sortValue, setSortValue] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);  
    const [sortOptions] = useState([
        { label: 'All' },  
        { label: '0-1', value: 'M1-0-1' },
        { label: '1-2', value: 'M1-1-2' },
        { label: '2-3', value: 'M1-2-3' },
        { label: '3-4', value: 'M1-3-4' },
        { label: '4-5', value: 'M1-4-5' },
    ]);
    const url = process.env.REACT_APP_APIURL
    const token = localStorage.getItem('token');

    const fetchReview = async (page) => {
        setIsLoading(true);  
        const params = {
            status: status,
            page: page,
            limit: 10,
            sortBy: 'reviewDate:desc',
            ...(submittedSearchTerm && { searchValue: submittedSearchTerm }),
            ...(sortValue &&  { sort: sortValue })  
        };
        
        try {
            const response = await axios.get(`${url}/reviewsByStatus`, {
                headers: {
                    'Authorization': token
                },
                params: params
            });
            
            const data = response.data;           
            if (Array.isArray(data.results)) {
                setReviews(data.results);
                setSelectedReview(data.results[0]);
                setTotalPages(data.totalPages);
                setTotalResults(data.totalResults);
            } else {
                console.error('Unexpected data format:', data);
            }
        } catch (error) {
            console.error('Error fetching reviews:', error);
        } finally {
            setIsLoading(false);  // Stop loading
        }
    };

    useEffect(() => {
        fetchReview(currentPage);
    }, [status, currentPage, submittedSearchTerm, sortValue]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setSubmittedSearchTerm(searchTerm);
        setCurrentPage(1);
    };

    const handleSortChange = (value) => {
        setSortValue(value);
        setCurrentPage(1);
        setIsDropdownOpen(false); 
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleOptionClick = (option) => {
        handleSortChange(option.value);
    };

    return (
        <div className="reviews-section">
            <div className='reviews-sidebar'>
                <div className="reviews-stickybar">
                    <div className="reviews-sortbar">
                        <h1 className="reviews-title">{status} Reviews <span className="count">{totalResults}</span></h1>
                        {status === 'New' && (
                            <div className="custom-dropdown">
                              <div className="dropdown-input-wrapper" onClick={toggleDropdown}>
        <input 
            type="text" 
            value={sortValue ? sortOptions.find(opt => opt.value === sortValue)?.label : 'Sort by'}
            readOnly 
        />
        <span className={`chevron ${isDropdownOpen ? 'open' : ''}`}></span>
    </div>
                                {isDropdownOpen && (
                                    <ul className="dropdown-list">
                                        {sortOptions.map(option => (
                                            <li 
                                                key={option.value} 
                                                onClick={() => handleOptionClick(option)}
                                            >
                                                {option.label}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="reviews-searchbar">
                        <form onSubmit={handleSearchSubmit}>
                            <input 
                                type="search" 
                                placeholder="Search by address"
                                value={searchTerm} 
                                onChange={handleSearchChange} 
                            />
                            <input type="submit" value="Search" />
                        </form>
                    </div>
                </div>
                <div className="review-tiles">
                    {isLoading ? ( 
                        <div className="loader"></div>
                    ) : (
                        reviews.map((review, index) => (
                            <ReviewTile
                                key={index}
                                active={selectedReview ? review._id === selectedReview._id : 0}
                                title={review.propertyData.ADDRESS} 
                                type={review.general.type}                           
                                pill={`Floor No-${review.general.floor || ''} &  Unit No-${review.general.unit || ''}`}
                                onSelect={() => setSelectedReview(review)} 
                            />
                        ))
                    )}
                    <Pagination 
                        currentPage={currentPage} 
                        totalPages={totalPages} 
                        onPageChange={(page) => setCurrentPage(page)} 
                    />
                </div>
            </div>
            {selectedReview && ( 
                <ReviewDetails 
                    selectedReview={selectedReview} 
                    fetchReview={fetchReview} 
                    setReviews={setReviews}
                    reviews={reviews}
                    setSelectedReview={setSelectedReview} 
                />         
            )}
            <div className="modal fade bd-example-modal-xl" id="imagemodal" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>         
        </div>
    );
}

export default MainContent;
