import React, { createContext, useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'

export const AuthContext = createContext();

export const AuthToken = ({ children }) => {
  const [token, setToken] = useState('');
  const [tokenReady, setTokenReady] = useState(false);

  useEffect(() => {
    Auth.currentSession()
      .then(data => {       
         localStorage.setItem('token' ,data.getAccessToken().getJwtToken())      
        const expiration = data.getAccessToken().getExpiration() * 1000;
        
        if (Date.now() >= expiration) {
        <AmplifySignOut />
        Auth.signOut();
        } else {
        setToken(data.getAccessToken().getJwtToken());
        localStorage.setItem('token' ,data.getAccessToken().getJwtToken()) 
        setTokenReady(true);
        }
      })
      .catch(err => console.log(err));
  }, []);
 
  return (
    <AuthContext.Provider value={{ token ,tokenReady }}>
      {children}
    </AuthContext.Provider>
  );
};
