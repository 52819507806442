
import React, { useState, useEffect } from 'react';

const ImageTile = ({ src, alt, onSelect, onRadioChange, published }) => {
  const [selectedValue, setSelectedValue] = useState(published ? 'publish' : 'unpublish');
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setSelectedValue(published ? 'publish' : 'unpublish');
  }, [published]);

  const handleImageClick = (e) => {
    e.stopPropagation(); 
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleRadioChange = (value) => {
    setSelectedValue(value);
    onRadioChange(value);
  };

  return (
    <div className="images-box" onClick={onSelect}>
      <img src={src} alt={alt} data-modal="zoomImage" onClick={handleImageClick} style={{ cursor: 'pointer' }} />
      <div>
        <label className="radio">
          <input
            type="radio"
            id={`publish-${alt}`}
            name={`image-${alt}`}
            value="publish"
            checked={selectedValue === 'publish'}
            onChange={() => handleRadioChange('publish')}
          />
          <span className="checkmark"></span>
          Published
        </label>
        <label className="radio">
          <input
            type="radio"
            id={`unpublish-${alt}`}
            name={`image-${alt}`}
            value="unpublish"
            checked={selectedValue === 'unpublish'}
            onChange={() => handleRadioChange('unpublish')}
          />
          <span className="checkmark"></span>
          Not Published
        </label>
      </div>        
      {isModalOpen && (
        <div className="image-tile-modal" onClick={closeModal}>
          <div className="image-tile-modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="image-tile-modal-close" onClick={closeModal}>&times;</span>
            <img src={src} alt={alt} className="image-tile-modal-image" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageTile;
