import React, { useEffect, useState, useRef, useContext } from 'react';
import axios from 'axios';
import $ from 'jquery';
import { AuthContext } from '../Auth';
const url = process.env.REACT_APP_APIURL
console.log(url,"url")

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  // const { token, tokenReady } = useContext(AuthContext);
  const { token } = useContext(AuthContext);
  console.log("TOKEN:", {token});

  useEffect(() => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${url}/dashboardData`,
      headers: {
        'Authorization': localStorage.getItem('token')
      }
    };
    
    axios.request(config)
      .then((response) => {
        setDashboardData(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  // useEffect(() => {
  //   if (tokenReady) {
  //     let config = {
  //       method: 'get',
  //       maxBodyLength: Infinity,
  //       url: `${url}v1/admin/dashboardData`,
  //       headers: {
  //         'Authorization': localStorage.getItem('token')
  //       }
  //     };

  //     axios.request(config)
  //       .then((response) => {
  //         setDashboardData(response.data.results);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // }, [tokenReady]);


  useEffect(() => {
    if (dashboardData) {
      const createPie = (dataElement, pieElement, sliceColor) => {
        const listData = [];
        $(dataElement + ' li span').each(function() {
          listData.push(Number($(this).html()));
        });

        let listTotal = 0;
        for (let i = 0; i < listData.length; i++) {
          listTotal += listData[i];
        }
        $(pieElement).append("<div class='totalData'>" + listTotal + "</div>");

        let offset = 0;
        const color = sliceColor;
        for (let i = 0; i < listData.length; i++) {
          const size = sliceSize(listData[i], listTotal);
          $(dataElement + ' li').eq(i).css("border-color", color[i]);
          iterateSlices(size, pieElement, offset, i, 0, color[i]);
          offset += size;
        }
      };

      const sliceSize = (dataNum, dataTotal) => {
        return (dataNum / dataTotal) * 360;
      };

      const addSlice = (sliceSize, pieElement, offset, sliceID, color) => {
        $(pieElement).append("<div class='slice " + sliceID + "'><span></span></div>");
        let sizeRotation = -179 + sliceSize;
        $(pieElement + " ." + sliceID).css({
          "transform": "rotate(" + offset + "deg) translate3d(0,0,0)"
        });
        $(pieElement + " ." + sliceID + " span").css({
          "transform": "rotate(" + sizeRotation + "deg) translate3d(0,0,0)",
          "background-color": color
        });
      };

      const iterateSlices = (sliceSize, pieElement, offset, dataCount, sliceCount, color) => {
        const sliceID = "s" + dataCount + "-" + sliceCount;
        const maxSize = 179;
        if (sliceSize <= maxSize) {
          addSlice(sliceSize, pieElement, offset, sliceID, color);
        } else {
          addSlice(maxSize, pieElement, offset, sliceID, color);
          iterateSlices(sliceSize - maxSize, pieElement, offset + maxSize, dataCount, sliceCount + 1, color);
        }
      };

      createPie("#pieID .legend", "#pieID .pie", ["#c82d5d", "#f1608d", "#fb98b7"]);
      createPie("#pieID2 .legend", "#pieID2 .pie", ["#ffb002"]);
      createPie("#pieID3 .legend", "#pieID3 .pie", ["#FF6384", "#63FF84", "#ffb002"]);
    }
  }, [dashboardData]);

  if (!dashboardData) {
    return <div className="loader"></div>;
  }

  const totalReviews = dashboardData.reviewCount.reduce((sum, review) => sum + review.count, 0);
  const publishedReviews = dashboardData.reviewCount.find(review => review.status === 'Published')?.count || 0;
  const newReviews = dashboardData.reviewCount.filter(review => review.status.toLowerCase() === 'new').reduce((sum, review) => sum + review.count, 0);
  const rejectedReviews = dashboardData.reviewCount.find(review => review.status === 'Rejected')?.count || 0;
  const propertiesReviewed = dashboardData.propertyCount;
  const totalUsers = dashboardData.userCount.reduce((sum, user) => sum + user.count, 0);
  const activeUsers = dashboardData.userCount.find(user => user.status === 'Active')?.count || 0;
  const inactiveUsers = dashboardData.userCount.find(user => user.status === 'Inactive')?.count || 0;

  return (
      <div className="pagetab" id="dashboard">
        <h1 className="reviews-title">Dashboard</h1>
        <section className="status-chart">
          <div className="reviews-box" id="pieID">
            <div className="title">Total Reviews  {totalReviews}</div>
            <div className="chart">
              <div className="pieID pie"></div>
              <ul className="pieID legend">
                <li>
                  <em>Published  </em>
                  <span>{publishedReviews}</span>
                </li>
                <li>
                  <em>New  </em>
                  <span>{newReviews}</span>
                </li>
                <li>
                  <em>Rejected  </em>
                  <span>{rejectedReviews}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="reviews-box" id="pieID2">
            <div className="title">Properties Reviewed {propertiesReviewed}</div>
            <div className="chart">
              <div className="pieID2 pie"></div>
              <ul className="pieID2 legend">
                <li>
                  <em>Reviewed  </em>
                  <span>{propertiesReviewed}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="reviews-box" id="pieID3">
            <div className="title">Total Users {totalUsers}</div>
            <div className="chart">
              <div className="pieID3 pie"></div>
              <ul className="pieID3 legend">
                <li>
                  <em>Active  </em>
                  <span>{activeUsers}</span>
                </li>
                <li>
                  <em>Inactive  </em>
                  <span>{inactiveUsers}</span>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
  );
};

export default Dashboard;
