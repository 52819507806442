
import React from 'react';

const ReviewTile = ({ active, pill, title,type, onSelect }) => {
  const isValidPill = pill && pill !== 'null & null' && pill !== 'undefined & undefined' && pill.trim() !== ''&& !pill.trim().startsWith('&') && type !== 'Landed House';

  return (
    <div className={`reviews-tile ${active ? 'active' : ''}`} onClick={onSelect}>
      {isValidPill && <span className="review-pill">{pill}</span>}
      <div className="review-title">
        {title}
      </div>
    </div>
  );
};

export default ReviewTile;
