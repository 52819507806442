import React, { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import axios from 'axios';
import ImageTile from './imageTile';
import { AuthContext } from '../Auth';
const url = process.env.REACT_APP_APIURL

const ReviewDetails = ({ selectedReview ,fetchReview , setReviews, reviews,setSelectedReview  }) => {
    const [isDisabled, setIsDisabled] = useState(false);

    const [isVerified, setIsVerified] = useState(selectedReview.general.isVerified);
    const handleCheckboxChange = (e) => {
        const newIsVerified = e.target.checked;
        setIsVerified(newIsVerified);
    };
    const [imageStatuses, setImageStatuses] = useState({ 
        propertyImages: {},
        houseImages: {},
        ownerImages: {}
    });
    const handleRadioChange = (alt, value) => {
        setImageStatuses(prevStatuses => ({
          ...prevStatuses,
          [alt]: value === 'publish'
        }));
      };
   
        const handleStatusChange = async (status) => {
            let rejectionReason = '';
            if (status === 'Rejected') {
                const { value: reason } = await Swal.fire({
                  title: 'Reason for Rejection',
                  input: 'textarea',
                  inputPlaceholder: 'Enter reason for rejection...',
                  showCancelButton: true,
                  inputValidator: (value) => {
                    if (!value) {
                      return 'Rejection reason is required';
                    }
                  }
                });
            
                if (!reason) {
                  return; 
                }
            
                rejectionReason = reason;
              }

        const updateImageStatus = (images) => {
          return images.map(image => ({
            ...image,
            isPublished: imageStatuses[image.image] ?? image.isPublished
          }));
        };   
    
        const updatedReview = {          
            _id:selectedReview._id ,
            // rejectionReason: rejectionReason,
            isVerified: isVerified,
            address:selectedReview.propertyData.ADDRESS,
            userEmail:selectedReview.userProfile.email,
            name:selectedReview.userProfile.name,
            familyName:selectedReview.userProfile.familyName,
            status:status,         
              propertyImages: updateImageStatus(selectedReview.propertyReview.propertyImages),
          
              houseImages: updateImageStatus(selectedReview.houseReview.houseImages),
          
              ownerImages: updateImageStatus(selectedReview.ownerReview.ownerImages)
          
          };
          if (status === 'Rejected') {
            updatedReview.rejectionReason = rejectionReason; 
          }
   
      try {
            const response = await axios.put(`${url}/updateReviewStatus`, updatedReview, {
              headers: {
                Authorization:localStorage.getItem('token'),
                'Content-Type': 'application/json'
              }
            });     
            
            const updatedReviews = reviews.filter(review => review._id !== selectedReview._id);
            setReviews(updatedReviews);
            
            const currentIndex = reviews.findIndex(review => review._id === selectedReview._id);
            const nextReview = updatedReviews[currentIndex] || updatedReviews[currentIndex - 1] || null;
            setSelectedReview(nextReview);
            swal('Success', `Review ${status} successfully`, 'success');
            // setSelectedReview(null);
        } catch (error) {
          swal('Error', `Failed to ${status} review`, 'error');
            // console.error('Error approving review', error);
          }
          setIsDisabled(true);
        };
    const [activeTab, setActiveTab] = useState('');
    const toggleTab = (tabId) => {
        setActiveTab(prevTab => (prevTab === tabId ? '' : tabId));
      };
      const curentYear = new Date().getFullYear();
      const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString(); 
      };
    if (!selectedReview) return <div className="loader"></div>;;

    return (
        <div className="review-details">
            <div className="review-tab show">
                <div className="review-user">
                    <div className="review-user-icon">{selectedReview.userProfile.name.substring(0, 2)}</div>
                    <div className="review-user-name">
                    <h4>{selectedReview.userProfile.name}</h4>
                        <span style={{ color: selectedReview.userProfile.email ? 'inherit' : 'red' }}>
                    {selectedReview.userProfile.email ? selectedReview.userProfile.email :"Deleted Account"}</span>

                    </div>
                </div>
                <div className="experience-box">
                    <div className="experience-rating">
                        <div className={'star star-sm-cont star-sm-' + Math.round(selectedReview.general.overallRatings*10/ 5) * 5}  ></div>
                        <div>{selectedReview.general.overallRatings}/5.00</div>
                    </div>
                    <div>Review Date- {formatDate(selectedReview.reviewDate)}</div>
                </div>
                <h1 className="experience-title">{selectedReview.general.title}</h1>
                <div className="experience-detail">
                    <p>{selectedReview.general.review}</p>
                    <p><span>Property type:</span> {selectedReview.general.type}</p>
                    <p><span>Lived in this house:</span> {selectedReview.general.propertyStayDuration.from} - {selectedReview.general.propertyStayDuration.to || curentYear}</p>
                </div>
                <section className="accordion accordion--radio">
                    {/* <div className="tab active"  onClick={() => setIsActive(!isActive)}> */}
                    <div className={`tab ${activeTab === 'houseReview' ? 'active' : ''}`} onClick={() => toggleTab('houseReview')}>
                        <label htmlFor="rd1" className="tab__label"  >House</label>
                        <div className="tab__content" >
                            <div className="tab__content-padding">
                                <div className="user-satisfaction">
                                    <h4>Overall Satisfaction:</h4>                                
                                    <div className={'star star-sm-cont star-sm-' + selectedReview.houseReview.houseOverallSatisfaction * 10}  ></div>                                    
                                </div>
                                <div className="overall-rating">
                                    <div className="overall-rating-column">
                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Maintenance:*</div>
                                            <div>
                                                <div className={'star star-sm-cont star-sm-' + selectedReview.houseReview.houseMaintenance * 10}  ></div>
                                            </div>
                                        </div>

                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Bathroom Fixtures:*</div>
                                            <div>
                                                <div className={'star star-sm-cont star-sm-' + selectedReview.houseReview.houseBathroom * 10}  ></div>
                                            </div>
                                        </div>

                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Windows and Doors:*</div>
                                            <div>
                                                <div className={'star star-sm-cont star-sm-' + selectedReview.houseReview.houseWindowsDoors * 10}  ></div>
                                            </div>
                                        </div>

                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Air Conditioning:*</div>
                                            <div>
                                                <div className={'star star-sm-cont star-sm-' + selectedReview.houseReview.houseAirConditioning * 10}  ></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="overall-rating-column">
                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Kitchen:*</div>
                                            <div className={'star star-sm-cont star-sm-' + selectedReview.houseReview.houseKitchen * 10}  ></div>
                                        </div>

                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Flooring and Walls:*</div>
                                            <div className={'star star-sm-cont star-sm-' + selectedReview.houseReview.houseFlooringWalls * 10}  ></div>
                                        </div>

                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Electrical and Plumbing:*</div>
                                            <div className={'star star-sm-cont star-sm-' + selectedReview.houseReview.houseElectricPlumbing * 10}  ></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="user-recommend">
                                    <h4>Would you recommend this apartment/unit to others?</h4>
                                    <div className="radio-group">
                                        <label className="radio">
                                            <input type="radio" checked="checked" disabled id="recommend8" name="recommend8" />
                                            <span className="checkmark"></span>
                                            {selectedReview.houseReview.houseRecommendation}
                                        </label>

                                    </div>
                                </div>
                                <div className="review-images">
                                    <h4>Photos</h4>

                                    {selectedReview.houseReview.houseImages.map((image, index) => (
          
                                        <ImageTile
                                            key={index}
                                            src={image.image}
                                            alt={`houseimage${index}`}
                                            published={image.isPublished}
                                            onRadioChange={(value) => handleRadioChange(image.image, value)}
                                            onSelect={() => console.log(`Image ${index} selected`)}
                                        />

                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`tab ${activeTab === 'ownerReview' ? 'active' : ''}`} onClick={() => toggleTab('ownerReview')}>
                        <label htmlFor="rd2" className="tab__label">Landlord</label>
                        <div className="tab__content">
                            <div className="tab__content-padding">
                                <div className="user-satisfaction">
                                    <h4>Overall Satisfaction:</h4>                                
                                    <div className={'star star-sm-cont star-sm-' + selectedReview.ownerReview.ownerOverallSatisfaction * 10}  ></div>                                    
                                </div>
                                <div className="overall-rating">
                                    <div className="overall-rating-column">
                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Responsiveness to inquiries and requests:</div>
                                            <div>
                                                <div className={'star star-sm-cont star-sm-' + selectedReview.ownerReview.ownerResponsiveness * 10}  ></div>
                                            </div>
                                        </div>

                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Maintenance and Repair issues:*</div>
                                            <div>
                                                <div className={'star star-sm-cont star-sm-' + selectedReview.ownerReview.ownerMaintenanceIssues * 10}  ></div>
                                            </div>
                                        </div>

                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Communication:*</div>
                                            <div>
                                                <div className={'star star-sm-cont star-sm-' + selectedReview.ownerReview.ownerCommunication * 10}  ></div>
                                            </div>
                                        </div>

                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Fairness of Lease Terms:*</div>
                                            <div>
                                                <div className={'star star-sm-cont star-sm-' + selectedReview.ownerReview.ownerFairnessTerms * 10}  ></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="overall-rating-column">
                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Respect for Privacy:*</div>
                                            <div className={'star star-sm-cont star-sm-' + selectedReview.ownerReview.ownerRespectPrivacy * 10}  ></div>
                                        </div>                                
                                    </div>
                                </div>
                                <div className="user-recommend">
                                    <h4>Are you happy with how the Landlord handled your security deposit?</h4>
                                    <div className="radio-group">
                                        <label className="radio">
                                            <input type="radio" checked disabled id="recommend5" name="recommend5" />
                                            <span className="checkmark"></span>
                                            {selectedReview.ownerReview.ownerHandlingDeposit}
                                        </label>
                                    </div>
                                </div>
                                <div className="user-recommend">
                                    <h4>Did you receive your entire security deposit back?</h4>
                                    <div className="radio-group">
                                        <label className="radio">
                                            <input type="radio" checked disabled id="recommend6" name="recommend6" />
                                            <span className="checkmark"></span>
                                            {selectedReview.ownerReview.ownerSecurityWithheld}
                                        </label>
                                    </div>
                                </div>
                                <div className="user-recommend">
                                    <h4>Would you recommend this Landlord to others?</h4>
                                    <div className="radio-group">
                                        <label className="radio">
                                            <input type="radio" checked disabled id="recommend7" name="recommend7" />
                                            <span className="checkmark"></span>
                                            {selectedReview.ownerReview.ownerRecommendation}
                                        </label>
                                    </div>
                                </div>
                                {/* <div className="review-images">
                                    <h4>Photos</h4>

                                    {selectedReview.ownerReview.ownerImages.map((image, index) => (

                                        <ImageTile
                                            key={index}
                                            src={image.image}
                                            alt={`ownerimage${index}`}
                                            published={image.isPublished}
                                            onRadioChange={(value) => handleRadioChange(image.image, value)}

                                            onSelect={() => console.log(`Image ${index} selected`)}
                                        />

                                    ))}

                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className={`tab ${activeTab === 'propertyReview' ? 'active' : ''}`} onClick={() => toggleTab('propertyReview')}>
                        <label htmlFor="rd3" className="tab__label">Property</label>
                        <div className="tab__content">
                            <div className="tab__content-padding">
                                <div className="user-satisfaction">
                                    <h4>Overall Satisfaction:</h4>                                
                                    <div className={'star star-sm-cont star-sm-' + selectedReview.propertyReview.propertyOverallSatisfaction * 10}  ></div>                                    
                                </div>
                                <div className="overall-rating">
                                    <div className="overall-rating-column">
                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Quality of Facilities:*</div>
                                            <div>
                                                <div className={'star star-sm-cont star-sm-' + selectedReview.propertyReview.propertyFacilities * 10}  ></div>
                                            </div>
                                        </div>

                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Maintenance and Cleanliness:*</div>
                                            <div>
                                                <div className={'star star-sm-cont star-sm-' + selectedReview.propertyReview.propertyMaintenance * 10}  ></div>
                                            </div>
                                        </div>

                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Security:*</div>
                                            <div>
                                                <div className={'star star-sm-cont star-sm-' + selectedReview.propertyReview.propertySecurity * 10}  ></div>
                                            </div>
                                        </div>

                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Noise Levels:*</div>
                                            <div>
                                                <div className={'star star-sm-cont star-sm-' + selectedReview.propertyReview.propertyNoiseLevel * 10}  ></div>
                                            </div>
                                        </div>
                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Value for Money:*</div>
                                            <div className={'star star-sm-cont star-sm-' + selectedReview.propertyReview.propertyValueForMoney * 10}  ></div>
                                        </div>
                                    </div>
                                    <div className="overall-rating-column">
                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Management and Staff:*</div>
                                            <div className={'star star-sm-cont star-sm-' + selectedReview.propertyReview.propertyManagementStaff * 10}  ></div>
                                        </div>

                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Parking:</div>
                                            <div className={'star star-sm-cont star-sm-' + selectedReview.propertyReview.propertyNoiseLevel * 10}  ></div>
                                        </div>

                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Location and Accessibility:*</div>
                                            <div className={'star star-sm-cont star-sm-' + selectedReview.propertyReview.propertyAccessibility * 10}  ></div>
                                        </div>
                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Public transportation options:*</div>
                                            <div className={'star star-sm-cont star-sm-' + selectedReview.propertyReview.propertyTransport * 10}  ></div>
                                        </div>

                                    </div>
                                </div>
                                <div className="user-recommend">
                                    <h4>Would you recommend this property to others?</h4>
                                    <div className="radio-group">
                                        <label className="radio">
                                            <input type="radio" checked="checked" disabled id="recommend4" name="recommend4" />
                                            <span className="checkmark"></span>
                                            {selectedReview.propertyReview.propertyRecommendation}
                                        </label>
                                    </div>
                                </div>
                                {/* <div className="review-images">
                                    <h4>Photos</h4>

                                    {selectedReview.propertyReview.propertyImages.map((image, index) => (

                                        <ImageTile
                                            key={index}
                                            src={image.image}
                                            alt={`propertyimage${index}`}
                                            published={image.isPublished}
                                            onRadioChange={(value) => handleRadioChange(image.image, value)}

                                            onSelect={() => console.log(`Image ${index} selected`)}
                                        />

                                    ))}

                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className={`tab ${activeTab === 'neighbourReview' ? 'active' : ''}`} onClick={() => toggleTab('neighbourReview')}>
                        <label htmlFor="rd4" className="tab__label">Neighbourhood</label>
                        <div className="tab__content">
                            <div className="tab__content-padding">
                                <div className="user-satisfaction">
                                    <h4>Overall Satisfaction:</h4>
                                    <div className={'star star-sm-cont star-sm-' + selectedReview.neighborhoodReview.neighborhoodOverallSatisfaction * 10}  ></div>                                    
                                </div>
                                <div className="overall-rating">
                                    <div className="overall-rating-column">
                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Safety and Security:*</div>
                                            <div>
                                                <div className={'star star-sm-cont star-sm-' + selectedReview.neighborhoodReview.neighborhoodSecurity * 10}  ></div>
                                            </div>
                                        </div>

                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Cleanliness and Maintenance:*</div>
                                            <div>
                                                <div className={'star star-sm-cont star-sm-' + selectedReview.neighborhoodReview.neighborhoodCleanliness * 10}  ></div>
                                            </div>
                                        </div>

                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Noise Levels:*</div>
                                            <div>
                                                <div className={'star star-sm-cont star-sm-' + selectedReview.neighborhoodReview.neighborhoodNoiseLevel * 10}  ></div>
                                            </div>
                                        </div>

                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Green Spaces and Parks:*</div>
                                            <div>
                                                <div className={'star star-sm-cont star-sm-' + selectedReview.neighborhoodReview.neighborhoodGreenSpaces * 10}  ></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="overall-rating-column">
                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Local Amenities:*</div>
                                            <div className={'star star-sm-cont star-sm-' + selectedReview.neighborhoodReview.neighborhoodAmenities * 10}  ></div>
                                        </div>

                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Schools:*</div>
                                            <div className={'star star-sm-cont star-sm-' + selectedReview.neighborhoodReview.neighborhoodSchools * 10}  ></div>
                                        </div>

                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Medical Facilities:*</div>
                                            <div className={'star star-sm-cont star-sm-' + selectedReview.neighborhoodReview.neighborhoodMedicalFacility * 10}  ></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="user-recommend">
                                    <h4>Would you recommend this neighbourhood to others?</h4>
                                    <div className="radio-group">
                                        <label className="radio">
                                            <input type="radio" checked="checked" disabled id="recommend3" name="recommend3" />
                                            <span className="checkmark"></span>
                                            {selectedReview.neighborhoodReview.neighborhoodRecommendation}
                                        </label>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {selectedReview.agentReview.agentAgencyUsed &&(
                    <div className={`tab ${activeTab === 'agentReview' ? 'active' : ''}`} onClick={() => toggleTab('agentReview')}>
                        <label htmlFor="rd5" className="tab__label">Agent</label>
                        <div className="tab__content">
                            <div className="tab__content-padding">
                                <div className="user-satisfaction">
                                    <h4>Overall Satisfaction:</h4>
                                    <div className={'star star-sm-cont star-sm-' + selectedReview.agentReview.agentOverallSatisfaction * 10}  ></div>                                    
                                </div>
                                <div className="overall-rating">
                                    <div className="overall-rating-column">
                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Understanding Your Needs:*</div>
                                            <div>
                                                <div className={'star star-sm-cont star-sm-' + selectedReview.agentReview.agentUnderstanding * 10}  ></div>
                                            </div>
                                        </div>

                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Professionalism:*</div>
                                            <div>
                                                <div className={'star star-sm-cont star-sm-' + selectedReview.agentReview.agentProfessionalism * 10}  ></div>
                                            </div>
                                        </div>

                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Knowledge and Expertise:*</div>
                                            <div>
                                                <div className={'star star-sm-cont star-sm-' + selectedReview.agentReview.agentKnowledge * 10}  ></div>
                                            </div>
                                        </div>

                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Responsiveness:*</div>
                                            <div>
                                                <div className={'star star-sm-cont star-sm-' + selectedReview.agentReview.agentResponsiveness * 10}  ></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="overall-rating-column">
                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Communication Skills:*</div>
                                            <div className={'star star-sm-cont star-sm-' + selectedReview.agentReview.agentResponsiveness * 10}  ></div>
                                        </div>

                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Negotiation Skills:*</div>
                                            <div className={'star star-sm-cont star-sm-' + selectedReview.agentReview.agentResponsiveness * 10}  ></div>
                                        </div>

                                        <div className="overall-rating-item">
                                            <div className="overall-rating-title">Property Selection:*</div>
                                            <div className={'star star-sm-cont star-sm-' + selectedReview.agentReview.agentResponsiveness * 10}  ></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="user-recommend">
                                    <h4>Did you use a property agent for your apartment search?</h4>
                                    <div className="radio-group">
                                        <label className="radio">
                                            <input type="radio" checked="checked" disabled id="recommend1" name="recommend1" />
                                            <span className="checkmark"></span>
                                           Yes
                                        </label>
                                    </div>
                                </div>
                                <div className="user-recommend">
                                    <h4>Would you recommend this agent to others?</h4>
                                    <div className="radio-group">
                                        <label className="radio">
                                            <input type="radio" checked="checked" disabled id="recommend2" name="recommend2" />
                                            <span className="checkmark"></span>
                                            {selectedReview.agentReview.agentRecommendation}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}
                </section>
                <div className="verify-post">
                    <label className="checkbox">
                        <input type="checkbox" id="stillLiving" name="radio1"  checked={isVerified}
                    onChange={handleCheckboxChange} />
                        <span className="checkmark"></span>
                        Verified review
                    </label>
                </div>
                <div className="review-action">
                    <input type="button" value="Reject" className="btn btn-outline" onClick={() => handleStatusChange('Rejected')} disabled={selectedReview.status === 'Rejected'} />
        <input type="button" value="Approve" className="btn btn-primary" onClick={() => handleStatusChange('Published')} disabled={selectedReview.status === 'Published'}  />
                </div>
            </div>
        </div>
    );
};

export default ReviewDetails;