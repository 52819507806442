import React from 'react';
// import './Header.css';
import Amplify from 'aws-amplify';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'
import logo from '../images/logo.png'
const handleSignOut = async () => {
  try {
       <AmplifySignOut />
      localStorage.clear();
      window.location.href = '/'; 
  } catch (error) {
      console.error('Error signing out: ', error);
  }
};
const Header = () => {
  return (
    <header className="header">
      <div className="header_left">
        <img src= {logo} alt="Logo" />
      </div>
      <div className="header_right">
        <a href="#" onClick={handleSignOut}>Sign out </a>
      </div>
    </header>
  );
};

export default Header;
