import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UserTable from '../components/userTable'; // Adjust the path accordingly
import Pagination from '../components/pagination';
const url = process.env.REACT_APP_APIURL
const Users = ({ status }) => {
    const [title, setTitle] = useState("");
    const [users, setUsers] = useState([]);
    const [actions, setActions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const[totalResults,setTotalResults] = useState();
    const fetchUsers = async (page) => {
        // let isActive = status === 'activeusers';
        const statusMap = {
            activeusers: 'Active',
            blockedusers: 'Inactive',
            deletedusers: 'deleted'
        };

        try {
            const response = await axios.get(
                `${url}/getUsers`, 
                {
                    params: {
                        page: page,
                        limit: 10,
                        sortBy: 'email',
                        status: statusMap[status], 
                    },
                    headers: {
                        'Authorization': localStorage.getItem('token')
                    }
                }
            );
            // console.log("API Response: ", response.data);
            setUsers(response.data.results); 
            setTotalPages(response.data.totalPages);
            setTotalResults(response.data.totalResults);
        } catch (error) {
            console.error("Error fetching users:", error); 
        }
    };

    useEffect(() => {
        switch (status) {
            case 'activeusers':
                setTitle("Active Users");
                setActions(['Block']);
                fetchUsers();
                break;
            case 'blockedusers':
                setTitle("Blocked Users");
                setActions(['Unblock']);
                fetchUsers();
                break;
            case 'deletedusers':
                setTitle("Deleted Users");
                fetchUsers();
                break;    
            default:
                break;
                
        }
        fetchUsers(currentPage);
    }, [status, currentPage]);   

    if (users.length ===0) {
        return <div className="loader"></div>;}
return(

    <div className="pagetab">
     <UserTable title={title} users={users} actions={actions} fetchUsers={fetchUsers} totalResults= {totalResults}/>
     <Pagination 
                currentPage={currentPage} 
                totalPages={totalPages} 
                onPageChange={(page) => setCurrentPage(page)} 
            />
  </div>
  ) 
   ;
};

export default Users;
