import React, { Component } from 'react';
import './App.css';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import Dashboard from './components/dashboard'
import { BrowserRouter as Router, Route, Routes,Navigate  } from 'react-router-dom';
import Header from './components/header';
import Sidebar from './components/sidebar';
import MainContent from './pages/reviewDetails';
import { AuthToken } from './Auth';
import Users from './pages/users';
Amplify.configure(aws_exports); 

function App() {
  
    return (
      <AuthToken>

      <Router>
      <div className="app">
        <Header />
        <div className="container">
          <Sidebar />
          <div className="container-right">
          <Routes>
            <Route path="/dashboard" element={<Dashboard/>} />
            <Route path="/newreview" element ={<MainContent status="New" />}/>
            <Route path="/published" element ={<MainContent status="Published" />}/>
            <Route path="/rejected" element ={<MainContent status="Rejected" />}/>
            <Route path="/activeusers" element ={<Users status="activeusers"/>}/>
            <Route path="/blockedusers" element ={<Users status="blockedusers"/>}/>
            {/* <Route path="/deletedusers" element ={<Users status="deletedusers"/>}/> */}
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
          </Routes>
          </div>
        </div>
      </div>
    </Router>  
    </AuthToken>

    );   
  
}

export default withAuthenticator(App);
