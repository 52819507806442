
import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
// import './Sidebar.css';

const Sidebar = () => {
  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [isUserOpen, setIsUserOpen] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  useEffect(() => {
    if (splitLocation[1] === "newreview" || splitLocation[1] === "Published" || splitLocation[1] === "rejected") {
      setIsReviewOpen(true);
    } else if (splitLocation[1] === "allusers" || splitLocation[1] === "activeusers" || splitLocation[1] === "blockedusers"|| splitLocation[1] === "deletedusers") {
      setIsUserOpen(true);
    } else {
      setIsReviewOpen(false);
      setIsUserOpen(false);
    }
  }, [location]);

  const toggleReviewMenu = () => {
    setIsReviewOpen(!isReviewOpen);
  };

  const toggleUserMenu = () => {
    setIsUserOpen(!isUserOpen);
  };

  return (
    <div className="container_sidebar">
      <ul className="sidebar_menu">
        <li>
          <a href="/dashboard">Dashboard</a>
        </li>
        <li className="submenu" onClick={toggleReviewMenu}>
          <span>Reviews</span>
          <ul className={isReviewOpen ? 'open' : ''}>
            <li className={splitLocation[1] === "newreview" ? "active" : ""}>
            <a href="/newreview">New </a>
              {/* <a href="/newreview">New <span className="count"></span></a> */}
            </li>
            <li className={splitLocation[1] === "Published" ? "active" : ""}>
              <a href="/Published">Published</a>
            </li>
            <li className={splitLocation[1] === "rejected" ? "active" : ""}>
              <a href="/rejected">Rejected</a>
            </li>
          </ul>
        </li>
        <li className="submenu" onClick={toggleUserMenu}>
          <span>Users</span>
          <ul className={isUserOpen ? 'open' : ''}>           
            <li className={splitLocation[1] === "activeusers" ? "active" : ""}>
              <a href="/activeusers">Active Users</a>
            </li>
            <li className={splitLocation[1] === "blockedusers" ? "active" : ""}>
              <a href="/blockedusers">Blocked Users</a>
            </li>
            <li className={splitLocation[1] === "deletedusers" ? "active" : ""}>
              {/* <a href="/deletedusers">Deleted Users</a> */}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
