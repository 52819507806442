import React ,{ useState,useEffect}from 'react';
import axios from 'axios';
import swal from 'sweetalert';
const url = process.env.REACT_APP_APIURL

const UserTable = ({ title, users, actions,fetchUsers,totalResults }) => {
    console.log(users,"users")

    const handleUserAction = (userName, isActive) => {
        const apiUrl = `${url}/changeUserStatus`;
    
        const headers = {
          'Authorization': localStorage.getItem('token'), 
          'Content-Type': 'application/json'
        };
        const newIsActive = isActive ? false : true; 
        const requestBody = {
          userName: userName,
          isActive: newIsActive
        };
    
        axios.put(apiUrl, requestBody, { headers })
          .then(response => {        
            console.log(`User ${userName} status updated successfully!`);
            swal('Success', `${userName} successfully ${actions}`, 'success');
            fetchUsers();
          })
          .catch(error => {

          swal('Error', `Failed to ${actions} ${userName}`, 'error');
   
          });
      };

   

  return (
    <div className="pagetab">
      <h1 className="reviews-title">{title} <span className="count">{totalResults}</span></h1>
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>User Id</th>
            <th>Email Id</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
        {users && users.length > 0 ? (
            users.map((user, index) => (
              <tr key={index}>
                <td>{user.name}</td>
                <td>{user.familyName}</td>
                <td>{user.profileName}</td>
                <td>{user.email}</td>
                <td>
                  {actions.map((action, idx) => (
                    <a
                      key={idx}
                      href="#"
                      className="btn btn-outline btn-inline"
                      onClick={() => handleUserAction(user.userName, user.isActive)}
  >
    {user.isActive ? 'Block' : 'Unblock'}
                      {/* {action} */}
                    </a>
                  ))}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No users found</td>
            </tr>
          )}
        </tbody>
      </table>

     
    </div>
  );
};


export default UserTable;
